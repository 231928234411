<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import {required} from "vuelidate/lib/validators";
import {languageHelper} from "@/helpers/language-helper";
import Swal from "sweetalert2";
import ecatCropper from "@/components/ecat-cropper.vue";
import {imageHelper} from "../../../../helpers/image-helper";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import SendAderloCashbackModal from "../../../../components/language/send-aderlo-cashback-modal.vue";

export default {

  components: {
    SendAderloCashbackModal,
    CustomCardSubtitle,
    Layout,
    PageHeader,
    ecatCropper
  },

  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        isBusy: false,

        items: []
      },

      modals: {
        createNewLanguage: {
          visible: false,

          form: {
            countryCodeISO639_1: "",
            flagUrl: "",
          },

          translationValues: null,
          newValue: ""
        }
      },

      imageFile: null,
      imageExtension: "",
      allLanguages: null
    };
  },

  validations() {
    return {
      modals: {
        createNewLanguage: {
          form: {
            countryCodeISO639_1: {required},
            flagUrl: {}
          }
        }
      }
    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('admin.languages.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "image", slot: true, label: "Flaga"},
        {key: "countryCodeISO639_1", label: "Kod kraju"},
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    async loadLanguages() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/language/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "page": page,
            "size": this.table.perPage
          }
        });

        this.table.items = data.languages
        this.table.totalRows = data.count;
        this.table.rows = data.count;
      } catch (error) {
        this.table.items = []
        errorCatcher.catchErrors(error)
      }

      return this.table.items
    },

    async openCreateNewLanguageModal(language) {
      if (language) {
        this.modals.createNewLanguage.form = Object.assign({}, language)
        await this.loadAllLanguages()
        await this.loadTranslations()
      } else {
        await this.loadAllLanguages()
        this.modals.createNewLanguage.translationValues = Object.fromEntries(
            [...this.allLanguages.map((obj) => [obj.countryCodeISO639_1, ""])]
        );
      }

      this.modals.createNewLanguage.visible = true
    },

    hideCreateNewLanguageModal() {
      this.allLanguages = null
      this.modals.createNewLanguage.translationValues = {}
      this.modals.createNewLanguage.visible = false
      this.modals.createNewLanguage.form.id = ""
      this.modals.createNewLanguage.form.countryCodeISO639_1 = ""
      this.modals.createNewLanguage.form.translationKey = ""
      this.modals.createNewLanguage.form.flagUrl = ""
      this.modals.createNewLanguage.newValue = ""
      this.imageFile = null
      this.imageExtension = ""
    },

    async loadAllLanguages() {
      try {
        const {data} = await axios.get(`/language`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.allLanguages = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadTranslations() {
      try {
        this.modals.createNewLanguage.translationValues = {}

        for (const language of this.allLanguages) {
          const {data} = await axios.get(`/translation/by-language-id/${language.id}`, {
            data: {},
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          });

          const translations = languageHelper.transformJSONToMap(JSON.parse(data.translations));
          this.modals.createNewLanguage.translationValues[language.countryCodeISO639_1] = translations[`ecat.language.${this.modals.createNewLanguage.form.countryCodeISO639_1}`]
        }
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async createOrEditLanguage() {
      this.submitted = true

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.modals.createNewLanguage.translationValues) {
        // eslint-disable-next-line no-prototype-builtins
        if (!this.modals.createNewLanguage.translationValues.hasOwnProperty(this.modals.createNewLanguage.form.countryCodeISO639_1.toLowerCase())) {
          if (!this.modals.createNewLanguage.newValue) {
            return
          }
        }

        for (const key in this.modals.createNewLanguage.translationValues) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.modals.createNewLanguage.translationValues.hasOwnProperty(key)) {
            const value = this.modals.createNewLanguage.translationValues[key];
            if (!value) {
              return;
            }
          }
        }
      }

      if (!this.imageFile && !this.modals.createNewLanguage.form.flagUrl) {
        return
      }

      if (this.imageFile) {
        try {
          await this.upload()
        } catch (ignored) {
          return
        }
      }

      const languageMap = Object.assign({}, this.modals.createNewLanguage.translationValues);
      if (this.modals.createNewLanguage.newValue) {
        languageMap[this.modals.createNewLanguage.form.countryCodeISO639_1.toLowerCase()] = this.modals.createNewLanguage.newValue
      }

      const json = JSON.stringify({
        "language": this.modals.createNewLanguage.form,
        "translationValues": languageMap
      });

      axios.put(`/language`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        Swal.fire("Sukces!",
            this.modals.createNewLanguage.form.id
                ? "Pomyślnie zaktualizowano język"
                : "Pomyślnie utworzono język",
            "success"
        ).then(() => this.$refs.table.refresh());

        this.hideCreateNewLanguageModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    async upload() {
      const formData = new FormData();
      formData.append('file', this.imageFile);
      formData.append('extension', this.imageExtension)

      try {
        const {data} = await axios.post(`/cdn/upload-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.modals.createNewLanguage.form.flagUrl = data.url
      } catch (error) {
        errorCatcher.catchErrors(error)
        throw error;
      }
    },

    uploadImage(input) {
      imageHelper.fetchFromInput(input).then((result) => {
        if (!result) {
          return;
        }

        const {dataUrl, extension} = result

        this.imageExtension = extension
        this.$refs.ecatCropper.openModal(dataUrl, "rectangle-stencil", 16 / 9)
      }).catch((error) => {
        console.error('Error occurred:', error);
      });
    },

    successCropImage({file}) {
      this.imageFile = file
    },

    helpVideos(item) {
      window.location = `/dashboard/admin/help-videos/${item.id}`
    },

    customPaths(item) {
      window.location = `/dashboard/admin/custom-paths/${item.id}`
    }

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('admin.languages.title')" :items="getItems()"/>
    <ecat-cropper ref="ecatCropper" @success="successCropImage"/>
    <send-aderlo-cashback-modal ref="sendAderloCashbackModal" @success="$refs.table.refresh()" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">

                <b-button variant="success" class="mr-2" @click="openCreateNewLanguageModal(null)">Dodaj nowy język</b-button>
                <router-link to="/dashboard/admin/translations" class="btn btn-outline-warning">Tłumaczenia
                </router-link>
              </div>
            </div>
            <ecat-table
                ref="table"
                :table="table"
                :fields="getFields()"
                :items="loadLanguages"
                :pagination-top="true"
            >
              <template v-slot:image="{ item }">
                <img :src="item.flagUrl" alt="Flag Icon" style="height: 4rem; width: 5rem;">
              </template>

              <template v-slot:aderloCashback="{ item }">
                <img v-if="item.aderloCashbackImageUrl" :src="item.aderloCashbackImageUrl" alt="Aderlo Cashback" style="height: 4rem; width: 12rem;" class="mx-2">

                <input accept="image/png,image/jpeg,image/jpg,image/gif,image/webp" type="file" :id="`send-aderlo-cashback-image-${item.id}`" @input="value => $refs.sendAderloCashbackModal.sendAderloCashback(item.id, value)" hidden/>
                <label :for="`send-aderlo-cashback-image-${item.id}`" class="btn btn-primary btn-sm">Wgraj</label>
<!--                <b-button variant="primary" class="btn-sm">Wgraj</b-button>-->
              </template>

              <template v-slot:action="{ item }">
                <div class="button-items mb-1">
                  <b-button variant="primary" class="btn-sm" @click="openCreateNewLanguageModal(item)">Edytuj</b-button>
                </div>

                <div class="button-items mb-1">
                  <b-button variant="info" class="btn-sm" @click="helpVideos(item)">Filmy pomocnicze</b-button>
                  <b-button variant="success" class="btn-sm" @click="customPaths(item)">Własne ścieżki</b-button>
                  <a :href="`/dashboard/admin/information-bars/${item.id}`" class="btn btn-primary btn-sm">Paski informacji</a>
                  <router-link :to="`/dashboard/admin/instructions/${item.id}`" tag="a" class="btn btn-outline-warning btn-sm">{{ $t('menuitems.admin.list.instructions') }}</router-link>
                </div>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="modals.createNewLanguage.visible"
        :title="modals.createNewLanguage.form.id ? 'Edytowanie języka' : 'Dodawanie nowego języka'"
        title-class="font-18"
        hide-footer
        @hide="hideCreateNewLanguageModal"
        @esc="hideCreateNewLanguageModal">
      <div class="form-group">
        <label for="image">Flaga</label>
        <br>

        <input accept="image/png,image/jpeg,image/jpg,image/gif,image/webp" type="file" id="send-image"
               @input="uploadImage" hidden/>
        <label for="send-image" class="btn btn-primary"
               :class="{ 'is-invalid': submitted && (!imageFile && !modals.createNewLanguage.form.flagUrl) }">{{
            $t('message.send')
          }}</label>
        <p v-if="imageFile">{{ imageFile.name }}</p>

        <div v-if="submitted && (!imageFile && !modals.createNewLanguage.form.flagUrl)" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Kod kraju</label>
        <input v-model="modals.createNewLanguage.form.countryCodeISO639_1" type="text" class="form-control"
               :class="{ 'is-invalid': submitted && $v.modals.createNewLanguage.form.countryCodeISO639_1.$error }"/>
        <div v-if="!$v.modals.createNewLanguage.form.countryCodeISO639_1.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Tłumaczenia języków:</label>

        <div class="row mb-2" v-for="(value, key) in modals.createNewLanguage.translationValues" :key="key">
          <div class="col-2 py-2">
            <b>{{ key.toUpperCase() }}</b>:
          </div>
          <div class="col-10">
            <input v-model="modals.createNewLanguage.translationValues[key]" type="text" class="form-control"
                   :class="{ 'is-invalid': submitted && !modals.createNewLanguage.translationValues[key] }"/>
            <div v-if="submitted && !modals.createNewLanguage.translationValues[key]" class="invalid-feedback">
              {{ $t('message.required') }}
            </div>
          </div>
        </div>

        <div class="row mb-2"
             v-if="modals.createNewLanguage.form.countryCodeISO639_1 && (modals.createNewLanguage.translationValues && !modals.createNewLanguage.translationValues.hasOwnProperty(modals.createNewLanguage.form.countryCodeISO639_1.toLowerCase()))">
          <div class="col-2 py-2">
            <b>{{ modals.createNewLanguage.form.countryCodeISO639_1.toUpperCase() }}</b>:
          </div>
          <div class="col-10">
            <input v-model="modals.createNewLanguage.newValue" type="text" class="form-control"
                   :class="{ 'is-invalid': submitted && !modals.createNewLanguage.newValue }"/>
            <div v-if="submitted && !modals.createNewLanguage.newValue" class="invalid-feedback">
              {{ $t('message.required') }}
            </div>
          </div>
        </div>

      </div>

      <div class="text-right">
        <b-button @click="createOrEditLanguage" variant="success">
          {{ modals.createNewLanguage.form.id ? 'Edytuj' : 'Utwórz' }}
        </b-button>
        <b-button class="ml-1" variant="danger" @click="hideCreateNewLanguageModal">{{
            $t('message.cancel')
          }}
        </b-button>
      </div>
    </b-modal>

  </Layout>
</template>